import parse from '@customink/html-react-parser';
import {Box, Link, Stack, Typography} from '@customink/pigment-react';
import React, {useContext, useState} from 'react';
import ProductContext from 'product_details_page/Context/productContext';
import ProductDetailsRow from 'product_details_page/ProductDetailsRow';
import trackProductDescriptionPageAccordionClick from 'utils/analytics';

export default function ProductDescription() {
  const {description, isApparel, sizingText, sizingUrl} =
    useContext(ProductContext);
  const [detailsAccordionExpanded, setDetailsAccordionExpanded] =
    useState(true);

  if (!description) {
    return null;
  }

  const handleSizingLink = () => {
    const popopts =
      'width=640,height=700,scrollbars=yes,toolbar=yes,menubar=no,location=yes,resizable=yes';
    window.open(sizingUrl, 'sizinglineup', popopts);
  };

  return (
    <ProductDetailsRow
      title="Product Description"
      accordionExpanded={detailsAccordionExpanded}
      accordionSummary="Product Description"
      accordionWrapperSx={{px: 0}}
      useAccordionAlways
      onAccordionClick={() => {
        setDetailsAccordionExpanded(!detailsAccordionExpanded);
        trackProductDescriptionPageAccordionClick(
          'description',
          detailsAccordionExpanded
        );
      }}>
      <Stack gap="1.5rem">
        <Typography
          variant="bodyLong1"
          className="pdp-product-description-parsed">
          {parse(description)}
        </Typography>
        <Box>
          <Stack gap="0.25rem" alignItems="flex-start">
            <Typography fontSize="0.875rem" lineHeight="125%" fontWeight="bold">
              {isApparel ? 'Fit' : 'Size'}
            </Typography>
            <Box
              component="span"
              sx={{
                ...(sizingUrl && {
                  borderRight: '1px solid #ededed',
                  paddingRight: '0.5rem'
                })
              }}>
              {sizingText}
            </Box>
          </Stack>
          {sizingUrl && (
            <Link onClick={handleSizingLink} role="link" tabIndex="0">
              Fit & Sizing Guide
            </Link>
          )}
        </Box>
      </Stack>
    </ProductDetailsRow>
  );
}
