// This file contains functions to push events to the data layer for Google Tag Manager (GTM) or other analytics tools.
const pushToDataLayer = (object) => {
  window.dataLayer.push(object);
};

const pushInteractionToDatalayer = ({action, category, label, value}) => {
  pushToDataLayer({
    event: 'interaction',
    interaction_action: action,
    interaction_category: category,
    interaction_label: label,
    interaction_value: value
  });
};
const trackProductDescriptionPageAccordionClick = (
  accordionName,
  isExpanded
) => {
  pushInteractionToDatalayer({
    action: 'click',
    category: 'product details page',
    label: `product ${accordionName}`,
    value: isExpanded ? 'collapse' : 'expand'
  });
};

export default trackProductDescriptionPageAccordionClick;
