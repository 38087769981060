import {Box, Typography} from '@customink/pigment-react';
import React, {useContext, useState} from 'react';
import ProductContext from 'product_details_page/Context/productContext';
import ProductDetailsRow from 'product_details_page/ProductDetailsRow';
import trackProductDescriptionPageAccordionClick from 'utils/analytics';

export default function ProductDetails() {
  const {decoration, features, minQty} = useContext(ProductContext);
  const [detailsAccordionExpanded, setDetailsAccordionExpanded] =
    useState(false);

  return (
    <ProductDetailsRow
      title="Product Details"
      accordionSummary="Product Details"
      accordionExpanded={detailsAccordionExpanded}
      accordionWrapperSx={{px: 0}}
      useAccordionAlways
      onAccordionClick={() => {
        setDetailsAccordionExpanded(!detailsAccordionExpanded);
        trackProductDescriptionPageAccordionClick(
          'details',
          detailsAccordionExpanded
        );
      }}>
      <>
        <ul>
          {features.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`detail-${index}`}>
              <Typography variant="bodyLong1">{item}</Typography>
            </li>
          ))}
        </ul>
        <Box>
          <Typography component="h5" variant="bodyLong1">
            Decoration
          </Typography>
          <Typography variant="bodyLong1">{decoration}</Typography>
        </Box>
        <Box>
          <Typography component="h5" variant="bodyLong1">
            Minimum Quantity
          </Typography>
          <Typography variant="bodyLong1">{minQty}</Typography>
        </Box>
      </>
    </ProductDetailsRow>
  );
}
